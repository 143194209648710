import nanostyled from 'nanostyled';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

export const Center = nanostyled('div', {
  base: 'mx-auto  max-w-8xl mt-10',
});
export const Container = nanostyled('section', {
  base: 'flex flex-wrap',
});

export const ImageLinkWrapper = nanostyled('div', {
  base: 'w-full sm:w-1/2 lg:w-1/3 border-solid border border-white',
});

export const ImageLink = nanostyled(Link, {
  base:
    'h-0 w-full aspect-ratio-square flex relative items-center border-solid border-white group cursor-pointer',
  border: '',
});

export const StyledImg = nanostyled(GatsbyImage, {
  base: 'w-full absolute top-0 bottom-0 left-0 right-0',
});

export const Icon = nanostyled(GatsbyImage, {
  base: 'w-24 xs:w-32 sm:w-24 filter-invert',
});

export const Overlay = nanostyled('div', {
  base:
    'w-full absolute top-0 bottom-0 left-0 right-0 opacity-85 transition duration-200 ease-in-out group-hover:opacity-0',
  color: 'bg-brand-blue',
});

export const TextContainer = nanostyled('div', {
  base:
    'm-8 xxs:m-10 absolute inset-0 z-10 flex flex-col justify-center transition duration-200 ease-in-out group-hover:opacity-0',
});

export const Text = nanostyled('h3', {
  base:
    'text-2xl xs:text-4xl sm:text-2xl md:text-4xl lg:text-3xl xl:text-4xl text-white leading-extraextratight font-light',
});
